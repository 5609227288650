import { globalHistory } from '@gatsbyjs/reach-router'
import 'src/fonts/stylesheets.css'

/*
LOAD SCRIPT ON RENDER
**/

export const onInitialClientRender = () => {
  /**
   * This is a workaround for a bug in Gatsby
   *
   * See https://github.com/gatsbyjs/gatsby/issues/8357 for more details
   */
  globalHistory._onTransitionComplete()
}

export const onRouteUpdate = () => {
  const payload = new URLSearchParams(window?.location.search).get('payload')
  const decodedPayload = payload ? JSON.parse(payload) : null

  if (decodedPayload || !window?.priceVersion) {
    window.priceVersion = decodedPayload?.priceVersion
  }
}
